import React, { useState } from 'react'


export const ValoresCorporativos = ({ t }) => {

    const [active, setActive] = useState();

    const changeLogoColoronMouseEnterHandler = (e) => {
        setActive(e.target.closest('div'))
    }


    return (

        <div className='who__chart' id='valores_corporativos'>
            <h1 className='intro--header who__heading'>{t('corpValues')}</h1>
            <img className='who__chart__company' src='/assets/logo_family.webp' alt='logo-cm' />
            <div className='chart-grid-container who__chart__grid'>
                <div className='who__chart__grid__row who__chart__grid__row--1'>
                    <div className={active && active.innerHTML.includes(t('excellence')) ? ' who__chart__grid__logo__container who__chart__grid__logo__container__blue--left' : 'who__chart__grid__logo__container who__chart__grid__logo__container--left '} onMouseEnter={changeLogoColoronMouseEnterHandler} >
                        <img className='who__chart__grid__round who__chart__grid__round--excelencia' src='/assets/valores_excelencia.webp' alt='excelencia' />
                        <img className='who__chart__grid__icon  who__chart__grid__icon--left' src={active && active.innerHTML.includes(t('excellence')) ? '/assets/logo_excelencia_azul.webp' : '/assets/logo_excelencia.webp'} alt='logo_excel' />
                        <span className={active && active.innerHTML.includes(t('excellence')) ? 'who__chart__grid__text who__chart__grid__text--blue who__chart__grid__text--left' : 'who__chart__grid__text  who__chart__grid__text--left'}>{t('excellence')}</span>
                    </div>
                    <div className={active && active.innerHTML.includes(t('adaptability')) ? 'who__chart__grid__logo__container who__chart__grid__logo__container__blue--right' : 'who__chart__grid__logo__container who__chart__grid__logo__container--right '} onMouseEnter={(e) => changeLogoColoronMouseEnterHandler(e)} >
                        <img className='who__chart__grid__round who__chart__grid__round--adaptabilidad' src='/assets/valores_adaptabilidad.webp' alt='adaptabilidad' />
                        <img className='who__chart__grid__icon  who__chart__grid__icon--right' src={active && active.innerHTML.includes(t('adaptability')) ? '/assets/valores_adaptabilidad_azul.webp' : '/assets/logo_adaptabilidad.webp'} alt='logo_adap' />
                        <span className={active && active.innerHTML.includes(t('adaptability')) ? 'who__chart__grid__text who__chart__grid__text--blue who__chart__grid__text--right' : 'who__chart__grid__text who__chart__grid__text--right'}>{t('adaptability')}</span>
                    </div>
                </div>
                <div className='who__chart__grid__row who__chart__grid__row--2'>
                    <div className={active && active.innerHTML.includes(t('ethics'))? 'who__chart__grid__logo__container who__chart__grid__logo__container__blue--left' : 'who__chart__grid__logo__container who__chart__grid__logo__container--left '} onMouseEnter={(e) => changeLogoColoronMouseEnterHandler(e)}>
                        <img className='who__chart__grid__round who__chart__grid__round--etica' src='/assets/valores_etica.webp' alt='etica' />
                        <img className='who__chart__grid__icon  who__chart__grid__icon--left' src={active && active.innerHTML.includes(t('ethics')) ? '/assets/logo_etica_azul.webp' : '/assets/logo_etica.webp'} alt='log-etica' />
                        <span className={active && active.innerHTML.includes(t('ethics')) ? 'who__chart__grid__text who__chart__grid__text--blue  who__chart__grid__text--left' : 'who__chart__grid__text  who__chart__grid__text--left'}>{t('ethics')}</span>
                    </div>
                    <div className={active && active.innerHTML.includes(t('transparency')) ? 'who__chart__grid__logo__container who__chart__grid__logo__container__blue--right' : 'who__chart__grid__logo__container who__chart__grid__logo__container--right '} onMouseEnter={(e) => changeLogoColoronMouseEnterHandler(e)}>
                        <img className='who__chart__grid__round who__chart__grid__round--transparencia' src='/assets/valores_transparencia.webp' alt='transparencia' />
                        <img className='who__chart__grid__icon  who__chart__grid__icon--right' src={active && active.innerHTML.includes(t('transparency')) ? '/assets/logo_lupa_azul.webp' : '/assets/logo_lupa.webp'} alt='logo-trans' />
                        <span className={active && active.innerHTML.includes(t('transparency')) ? 'who__chart__grid__text who__chart__grid__text--blue who__chart__grid__text--right' : 'who__chart__grid__text who__chart__grid__text--right'}>{t('transparency')}</span>
                    </div>
                </div>
                <div className='who__chart__grid__row who__chart__grid__row--3'>
                    <div className={active && active.innerHTML.includes(t('commitment')) ? 'who__chart__grid__logo__container who__chart__grid__logo__container__blue--left' : 'who__chart__grid__logo__container who__chart__grid__logo__container--left'} onMouseEnter={(e) => changeLogoColoronMouseEnterHandler(e)}>
                        <img className='who__chart__grid__round who__chart__grid__round--compromiso' src='/assets/valores_compromiso.webp' alt='compromiso' />
                        <img className='who__chart__grid__icon  who__chart__grid__icon--left who__chart__grid__icon--compromiso' src={active && active.innerHTML.includes(t('commitment')) ? '/assets/logo_compromiso_azul.webp' : '/assets/logo_compromiso.webp'} alt='log-comp' />
                        <span className={active && active.innerHTML.includes(t('commitment')) ? 'who__chart__grid__text who__chart__grid__text--blue who__chart__grid__text--left' : 'who__chart__grid__text  who__chart__grid__text--left'}>{t('commitment')}</span>
                    </div>
                    <div className={active && active.innerHTML.includes(t('family')) ? 'who__chart__grid__logo__container who__chart__grid__logo__container__blue--right' : 'who__chart__grid__logo__container who__chart__grid__logo__container--right'} onMouseEnter={(e) => changeLogoColoronMouseEnterHandler(e)}>
                        <img className='who__chart__grid__round who__chart__grid__round--familia' src='/assets/valores_familia.webp' alt='familia' />
                        <img className='who__chart__grid__icon who__chart__grid__icon--right' src={active && active.innerHTML.includes(t('family')) ? '/assets/logo_familia_azul.webp' : '/assets/logo_familia.webp'} alt='log-fam' />
                        <span className={active && active.innerHTML.includes(t('family')) ? 'who__chart__grid__text who__chart__grid__text--blue who__chart__grid__text--right' : 'who__chart__grid__text who__chart__grid__text--right'}>{t('family')}</span>
                    </div>

                </div>
            </div>
        </div>
    )
}



