import React from 'react'

export const Trencadis = () => {
    return (
        <div className='who__grid__trencadis'>
            <div className=" who__grid__trencadis__container">
                <div className="who__grid__trencadis__item who__grid__trencadis__item--1"></div>
                <div className="who__grid__trencadis__item who__grid__trencadis__item--2"></div>
                <div className="who__grid__trencadis__item who__grid__trencadis__item--3"></div>
                <div className="who__grid__trencadis__item who__grid__trencadis__item--4"></div>
                <div className="who__grid__trencadis__item who__grid__trencadis__item--5"></div>
                <div className="who__grid__trencadis__item who__grid__trencadis__item--6"></div>
                <div className="who__grid__trencadis__item who__grid__trencadis__item--7"></div>
                <div className="who__grid__trencadis__item who__grid__trencadis__item--8"></div>
                <div className="who__grid__trencadis__item who__grid__trencadis__item--9"></div>
            </div>
        </div>
    )
}
