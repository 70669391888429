import React, { useState, useEffect } from 'react'
import { deleteCompany } from '../../api/endpoints';
import { getCompanies } from '../../api/endpoints';
import { editCompany } from '../../api/endpoints';
import { useForm } from "react-hook-form";

export const ManageParticipada = ({ hideNavbar }) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [inputFileValue, setInputFileValue] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const categories = ['Participaciones Industriales', 'Inversiones Startups/Tecnológicas', 'Inversiones Financieras', 'Inversiones Inmobiliarias'];
    const isNotMobile = window.innerWidth > 1024
    const [show, setShow] = useState(false);
    const [activeItem, setActiveItem] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (activeIt) => {
        setShow(true);
        setActiveItem(activeIt)
    }

    const handleCategories = categories.map((doc, index) => {
        return (
            <option key={index} value={doc}>
                {doc}
            </option>
        )
    })

    const handleInputFileChange = (e) => {
        setInputFileValue(e.target.files[0].name)
    };



    useEffect(() => {
        const fetchinfo = async () => {

            try {
                hideNavbar(true)
                const result = await getCompanies();
                setData(result.data);
            } catch (error) {
                console.log(error)
            }
        }

        fetchinfo()
    }, [isLoading, hideNavbar])

    const onSubmit = async (data) => {
        try {
            setIsLoading(true)
            const companyForm = new FormData();
            companyForm.append('companyLogo', data.companyLogo[0]);
            companyForm.append("name", data.name);
            companyForm.append("year", data.year);
            companyForm.append('sector', data.sector);
            companyForm.append("category", data.category);
            companyForm.append("website", data.website);
            companyForm.append("sede", data.sede);
            companyForm.append("englishDescription", data.englishDescription);
            companyForm.append("spanishDescription", data.spanishDescription)
            companyForm.append("stopInvestingYear", data.stopInvestingYear)

            await editCompany(activeItem._id, companyForm);
            reset()
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteCompanyHandler = (companyId) => {
        setIsLoading(true)
        deleteCompany(companyId)
        setTimeout(() => {
            setIsLoading(false)
        }, 300)
        document.location.reload()

    }
    return (
        <div className="backoffice backoffice__edit">

            {
                data ?
                    data.map((items, index) => {
                        return (
                            <div key={index}>
                                <div className='backoffice__div'>

                                    <label className='font-weight-bold text-center'>{items.name}</label>

                                    <div className="backoffice__manage">
                                        <button onClick={() => handleShow(items)} className='backoffice__button backoffice__button--edit'>Editar Compañía</button>
                                        <button onClick={() => deleteCompanyHandler(items._id)} className=' backoffice__button backoffice__button--delete'>Eliminar Compañía</button>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                    :
                    <p>No hay compañías para eliminar</p>

            }

            <>
                {
                    activeItem && show ?
                        <div className="backoffice__modal">
                            <form className='backoffice__edit-container' onSubmit={ handleSubmit(onSubmit) }>
                                <button className="backoffice__close" onClick={ handleClose } >CERRAR</button>
                                { errors.companyLogo && <span className='backoffice__error-inputs'>Este campo es obligatorio</span> }
                                <div>
                                    <label>Logo de la Empresa</label>
                                    <label htmlFor='logo-upload' className={errors.companyLogo ? ' backoffice__error-inputsbackoffice__inputs  backoffice__button' : 'backoffice__button'}>{ inputFileValue === undefined ? 'Logo de la Empresa' : inputFileValue.substring(22, -1) + '...'}</label>
                                    {
                                        isNotMobile ?
                                            <label style={{ display: 'none' }} htmlFor='logo-upload'>Explorar archivos</label>
                                            :
                                            <label htmlFor='logo-upload' ><i className="fas fa-upload backoffice__company-logo-upload"></i></label>
                                    }
                                    <input
                                        id='logo-upload'
                                        onChange={handleInputFileChange}
                                        type="file"
                                        name='companyLogo'
                                        {...register('companyLogo')}
                                        className={errors.companyLogo ? 'backoffice__error-inputsbackoffice__inputs  backoffice__upload-logo' : 'backoffice__upload-logo '}
                                    />
                                </div>

                                {errors.name && <span className='backoffice__error-inputsbackoffice__inputs '> Este campo es obligatorio</span>}
                                <label>Nombre de la Empresa</label>
                                <input
                                    className={errors.name ? 'backoffice__error-inputs' : 'backoffice__inputs '}
                                    name="name"
                                    {...register("name")}

                                    placeholder={activeItem.name}
                                />

                                {errors.year && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                                <label>Año de inversión</label>
                                <input
                                    className={errors.year ? 'backoffice__error-inputs' : 'backoffice__inputs '}
                                    name="year"
                                    {...register("year")}

                                    placeholder={activeItem.year}
                                />
                                <label>Año de desinversión</label>
                                <input
                                    className={errors.stopInvestingYear ? 'backoffice__error-inputs' : 'backoffice__inputs '}
                                    name="stopInvestingYear"
                                    {...register("stopInvestingYear")}

                                    placeholder={activeItem.stopInvestingYear}
                                />

                                {/* {errors.sector && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                                <label>Sector</label>
                                <input
                                    className={errors.sector ? 'backoffice__error-inputs' : 'backoffice__inputs '}
                                    name='sector'
                                    ref={register}
                                    placeholder='Sector'
                                /> */}
                                {errors.sede && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                                <label>Sede</label>
                                <input
                                    className={errors.sede ? 'backoffice__error-inputs' : 'backoffice__inputs '}
                                    name="sede"
                                    {...register("sede")}
                                    placeholder={activeItem.sede}
                                />
                                {errors.category && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                                <label>Categoría</label>
                                <p>Actual:<b>{activeItem.category}</b></p>
                                <select
                                    className={errors.category ? 'backoffice__error-inputs' : 'backoffice__inputs '}
                                    name="category"
                                    {...register("category")}
                                    placeholder='Categoría'
                                >
                                    <option value=''>Seleccionar</option>
                                    {handleCategories}
                                </select>

                                {errors.website && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                                <label>Sitio web de la Empresa</label>
                                <input
                                    className={errors.website ? 'backoffice__error-inputs' : 'backoffice__inputs '}
                                    name="website"
                                    {...register("website")}
                                    placeholder={activeItem.website}
                                />
                                {errors.description && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                                <label>Descripción de la Empresa</label>

                                <label>Inglés</label>
                                <textarea
                                    className={errors.description ? 'backoffice__error-inputs' : 'backoffice__inputs '}
                                    type='textarea'
                                    name="englishDescription"
                                    {...register("englishDescription")}
                                    placeholder={activeItem.description.englishDescription}
                                />

                                <label>Castellano</label>
                                <textarea
                                    className={errors.description ? 'backoffice__error-inputs' : 'backoffice__inputs '}
                                    type='textarea'
                                    name="spanishDescription"
                                    {...register("spanishDescription")}
                                    placeholder={activeItem.description.spanishDescription}
                                />
                                {
                                    isLoading ?
                                        <p>Compañía actualizada correctamente</p>
                                        :
                                        <button type='submit' className='backoffice__button'>Editar Participada</button>
                                }

                            </form>
                        </div>
                    :
                    null
                }
            </>
        </div>
    )
}
