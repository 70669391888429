import React from 'react'
import { MenuParticipadas } from './MenuParticipadas';

export const Participadas = ({ t, languages }) => {

    return (
        <div>
            <div className='participadas__stairs' src='escalera.webp' alt='escalera' />
            <h1 className='participadas__title' id='participadas'>{t('participadas-title')}</h1>
            <MenuParticipadas t={t} languages={languages}/>
        </div>
    )
}
