import React from 'react'
import Carousel from 'react-material-ui-carousel';

export const CarouselFotos = () => {

    const items = ['cementera_2', 'cementera', 'cementos_antiguo', 'cms_mapa', 'entrada_casa', 'escalera', 'modernista_1', 'modernista_2', 'oficina', 'panots', 'terraza']
    return (
        <Carousel
         activeIndicatorIconButtonProps={{
                style: {
                    color: 'white',
                    backgroundColor: 'white',
                    border: '3px solid white',
                    height: '1em',
                    width: '1em',
                    margin: '0 2px 0 2px'
                }
            }}
            indicatorIconButtonProps={{
                style: {
                    color: 'transparent',
                    border: '2px solid white',
                    height: '.9em',
                    width: '.9em',
                    margin: '0 2px 0 2px',
                    position: 'relative',
                    bottom: '3em'
                }
            }}
            className='who__pictures-carousel'>
            {
                items.map((item, i) => <Photo key={i} item={item} />)
            }
        </Carousel>
    )
}

function Photo({ item }) {
    return <img className='who__pictures-carousel__pic' src={`/assets/${item}.webp`} alt={item} />
}