import React from 'react';
import { Intro } from './Intro';
import { OrganosDeGobierno } from './OrganosDeGobierno';
import { Responsabilidad } from './Responsabilidad';

export const QueHacemos = ({t}) => {
    return (
        <div className='what'  >
            <h1 className='what__heading' id='que-hacemos'>{t('whatWeDo')}</h1>
            <Intro t={t}/>
            <OrganosDeGobierno t={t}/>
            <Responsabilidad t={t}/>
        </div>
    )
}
