import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';

export const CarouselMisionVision = ({ t }) => {

    const [itemChanged, setItemChanged] = useState(false);
    const items = [
        {
            name: t('companyInfo-8'),
            description: t('companyInfo-9'),
        }, {
            name: t('companyInfo-6'),
            description: t('companyInfo-7'),
        }];

    return (

        <Carousel
            onChange={(_, active) => {
                if (active === 0 || active === 1) {
                    setItemChanged(!itemChanged)
                }
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    color: 'white',
                    backgroundColor: 'white',
                    border: '3px solid white',
                    height: '1em',
                    width: '1em',
                    margin: '0 2px 0 2px'
                }
            }}
            indicatorIconButtonProps={{
                style: {
                    color: 'transparent',
                    border: '2px solid white',
                    height: '1em',
                    width: '1em',
                    margin: '0 2px 0 2px',

                }
            }}
            interval={10000}
            className={itemChanged ? 'who__carousel who__carousel__item--2' : 'who__carousel who__carousel__item--1'} >
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    )
}

function Item(props) {
    const { name, description } = props.item;
    return (
        <div id='mision_y_vision' >
            <h3 className='who__carousel__heading'>{name}</h3>
            <p className='who__carousel__text'>{description}</p>
        </div>
    )
}