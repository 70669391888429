import React from 'react'
import { useTranslation } from 'react-i18next'

export const NotFound = () => {
    const [, i18n ] = useTranslation();

    return (
        <div>
        {
            i18n.language === 'es' ?
            <h1>Lo sentimos esta página no existe</h1>
            :
            i18n.language === 'cat' ?
            <h1>Ho sentim, aquesta página no existeix</h1>
            :
            i18n.language === 'en' ?
            <h1>We are sorry, this page is not available</h1>
            :
            null
        }
        </div>
    )
}
