import React, { useState } from 'react'
import './scss/main.scss'
import { Navbar } from './Components/Home';
import { LandingPage } from './pages/LandingPage';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import { CookiesPolicy } from './Components/Cookies';
import { PrivacyPolicy } from './Components/Cookies';
import { Legal } from './Components/Cookies';
import { NavbarExternal } from './Components/Home/NavbarExternal';
import { CreateParticipada } from './Components/CreateParticipada/CreateParticipada';
import { ManageParticipada } from './Components/ManageParticipada';
import { NotFound } from './Components/NotFound.js'

function App() {
  const [t, i18n] = useTranslation();
  const [, setShowLanguages] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [hideNavbar, setHideNavbar] = useState(false);

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang)
  }



  return (

    <div className="app">
      {showNavbar && !hideNavbar ? <Navbar handlers={[changeLanguageHandler]} t={t} /> : !showNavbar && !hideNavbar ? <NavbarExternal handlers={[changeLanguageHandler]} t={t} /> : null}
      <Router>
        <Switch>
          <Route exact path='/' render={(props) => (<LandingPage {...props} t={t} showNavbar={setShowNavbar} />)} />
          {/* <Route exact path='/cookies-policy' render={(props) => (<CookiesPolicy {...props} t={t} handlers={[changeLanguageHandler]} hooks={setShowLanguages} />)} /> */}
          <Route exact path='/privacy-policy' render={(props) => (<PrivacyPolicy {...props} t={t} handlers={[changeLanguageHandler]} hooks={setShowLanguages} />)} />
          <Route exact path='/legal' render={(props) => (<Legal {...props} t={t} handlers={[changeLanguageHandler]} hooks={setShowLanguages} />)} />
          <Route exact path='/create-participada' render={(...props) => (<CreateParticipada {...props} hideNavbar={setHideNavbar} />)} />
          <Route exact path='/edit-participada' render={(...props) => (<ManageParticipada {...props} hideNavbar={setHideNavbar} />)} />
          <Route component={ NotFound } />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
