import React from 'react';
import { QuienesSomos } from '../Components';
import { QueHacemos } from '../Components';
import { Home } from '../Components';
import { Contact } from '../Components';
import { Participadas } from '../Components';

import $ from 'jquery'

export const LandingPage = ({ t, hooks, languages,showNavbar }) => {
    
    $(document).scroll(function () {
        let y = $(this).scrollTop();
        if (y > 200) {
               $('.landing__uparrow').fadeIn();
        } else {
            $('.landing__uparrow').fadeOut();
        }

    });

    return (
        <>
            <Home  t={t} hooks={hooks} showNavbar={showNavbar}/>
            <QuienesSomos t={t} />
            <QueHacemos t={t} />
            <Participadas t={t} languages={languages} />
            <Contact t={t} showNavbar={showNavbar} />
            <a href='#top'><button className='landing__uparrow'><i className="text-dark fas fa-arrow-up"></i></button></a>
        </>
    )
}
