import React, { useState, useEffect } from 'react';
import { ParticipadaFicha } from './ParticipadaFicha';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Modal from 'react-modal';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useTranslation } from 'react-i18next';
import listParticipadas from '../../data/participadas.json'

export const CarouselParticipadas = ({ category, hooks, isVisible }) => {

    const [setShowPortfolioStartups, setShowDesinversionesStartups, showPortfolioStartups, showDesinversionesStartups, setBtnDesActive, setBtnPortfolioActive, btnPortFolioActive, btnDesActive] = hooks;
    const [desinversiones, setShowDesinversiones] = useState([]);
    const [companyInfo, setCompanyInfo] = useState([]);
    const [indvCompanyData, setIndvCompanyData] = useState({})
    const [error, setError] = useState(false);
    const [portFolio, setShowPortFolio] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [t] = useTranslation();

    const responsive = {
        0: { items: 1 },
        650: { items: 1 },
        1024: { items: 4 },
        3000: { items: 4 },
        4000: { items: 4 },
    };


    useEffect(() => {
        const fetchCompanyInfo = async () => {
            try {
                setIsLoading(true)
                setCompanyInfo(listParticipadas);
                listParticipadas.length > 0 && setIsLoading(false);
            } catch (err) {
                setIsLoading(false)
                setError(true)
            }
        }
        fetchCompanyInfo()
    }, []);

    const filtered = companyInfo.filter((comp) => comp.category.includes(category));

    const showPortFolio = (companyInfo) => {
        setBtnPortfolioActive(false)
        setBtnDesActive(true);
        setShowPortfolioStartups(!showPortfolioStartups);
        setShowDesinversionesStartups(true);
        const portfolioStartups = companyInfo.filter((c) => !Number(c.stopInvestingYear) && c.category.includes('Startup'))
        setShowPortFolio(portfolioStartups);
    };

    const showDesinversiones = (companyInfo) => {
        setBtnPortfolioActive(true)
        setBtnDesActive(false);
        setShowPortfolioStartups(true);
        setShowDesinversionesStartups(false);
        const desinversionesStartups = companyInfo.filter((c) => Number(c.stopInvestingYear) && c.category.includes('Startup'))
        console.info(desinversionesStartups, companyInfo)
        setShowDesinversiones(desinversionesStartups);
    };

    const showModalHandler = (companyInfo) => {
        setOpenModal(!openModal);
        setIndvCompanyData(companyInfo)
    }

    const closeModalHandler = () => setOpenModal(false);

    return (

        <div>

            {error && <span className='participadas__error'>{t('error.loading.companies')}</span>}
            {
                isLoading ?
                    <Loader type="TailSpin" color="#014CB1" height={100} width={100} className='participadas__loader' />
                    :
                    filtered && !showPortfolioStartups && !showDesinversionesStartups ?
                        <>
                            {
                                category.includes('Startups') &&
                                <div className='participadas__buttons'>
                                    <button className={ btnPortFolioActive ? ' participadas__button participadas__button--active' : 'participadas__button' } onClick={() => showPortFolio(companyInfo)}>{t('portfolio-actual')}</button>
                                    <button className={ btnDesActive ? 'participadas__button participadas__button--active' : 'participadas__button' } onClick={() => showDesinversiones(companyInfo)}>{t('desinversiones')}</button>
                                </div>
                            }
                            {
                                <AliceCarousel
                                    controlsStrategy="responsive"
                                    autoPlay={ isVisible }
                                    autoPlayInterval={ 5000 }
                                    disableDotsControls={ true }
                                    infinite={ false }
                                    responsive={ responsive }>
                                    {
                                        category.includes('Startups') && showPortfolioStartups ?
                                            portFolio
                                                .sort((a, b) => a.order - b.order)
                                                .map((company, i) => <Company key={i} companyLogo={company.companyLogo} companyInfo={company} />)
                                            :
                                            filtered
                                                .sort((a, b) => a.order - b.order)
                                                .map((company, i) => <Company key={i} companyLogo={company.companyLogo} companyInfo={company} category={category} />)
                                    }
                                </AliceCarousel>
                            }
                        </>
                        :
                        showPortfolioStartups ?
                            <>
                                <div className='participadas__buttons'>
                                    <button className={ btnPortFolioActive ? 'participadas__button participadas__button--active' : 'participadas__button' } onClick={() => showPortFolio(companyInfo)}>{t('portfolio-actual')}</button>
                                    <button className={ btnDesActive ? 'participadas__button participadas__button--active' : 'participadas__button' } onClick={ () => showDesinversiones(companyInfo)}>{t('desinversiones')}</button>
                                </div>

                                <AliceCarousel
                                    controlsStrategy='responsive'
                                    autoPlay={ isVisible }
                                    autoPlayInterval={ 5000 }
                                    disableDotsControls={ true }
                                    infinite={ false }
                                    responsive={ responsive }>

                                    {
                                        portFolio
                                            .sort((a, b) => a.order - b.order)
                                            .map((company, i) => <Company key={i} companyLogo={company.companyLogo} companyInfo={company} />)
                                    }
                                </AliceCarousel>
                            </>
                            :
                            showDesinversionesStartups &&
                            <>
                                <div className='participadas__buttons'>
                                    <button className={ btnPortFolioActive ? 'participadas__button participadas__button--active' : 'participadas__button' } onClick={() => showPortFolio(companyInfo)}>{t('portfolio-actual')}</button>
                                    <button className={ btnDesActive ? 'participadas__button participadas__button--active' : 'participadas__button' } onClick={ () => showDesinversiones(companyInfo)}>{t('desinversiones')}</button>
                                </div>

                                <AliceCarousel
                                    controlsStrategy='responsive'
                                    className='participadas__logo'
                                    autoPlay={ isVisible }
                                    autoPlayInterval={ 5000 }
                                    disableDotsControls={ true }
                                    infinite={ false }
                                    responsive={ responsive }>
                                    {
                                        desinversiones
                                            .map((company, i) => <Company key={i} companyLogo={company.companyLogo} companyInfo={company} />)
                                    }
                                </AliceCarousel>

                            </>
            }
            {
                openModal &&
                <Modal
                    ariaHideApp={false}
                    isOpen={openModal}
                    onRequestClose={closeModalHandler}
                    className='participadas__modal'
                >
                    <ParticipadaFicha t={t} isOpen={openModal} modalHandler={closeModalHandler} companyInfo={indvCompanyData} />
                </Modal>
            }
        </div>


    );

    function Company({ companyLogo, companyInfo, category }) {
        const includesSicav = category && category.includes('Finan');
        const gesiuris = category && companyInfo.name.includes('Gesiuris');
        const industriales = category && category.includes('Industrial');
        const sicav = companyInfo.name.includes('SICAV')
        return <img id='participadas' onClick={() => showModalHandler(companyInfo)} className={ includesSicav && gesiuris ? 'participadas__gesiuris' : includesSicav && sicav ? 'participadas__sicav' : industriales ? 'participadas__industriales participadas__carousel-img' : 'participadas__carousel-img'} src={companyLogo} alt='company-logo' />
    }
}
