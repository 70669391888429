import React, { useState, useEffect } from 'react'
import { createCompany } from '../../api/endpoints';
import { useForm } from "react-hook-form";

export const CreateParticipada = ({ hideNavbar }) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [inputFileValue, setInputFileValue] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const handleInputFileChange = (e) => {
        setInputFileValue(e.target.files[0].name)
    };

    const categories = ['Participaciones Industriales', 'Inversiones Startups', 'Inversiones Financieras', 'Inversiones Inmobiliarias'];
    const isNotMobile = window.innerWidth > 1024

    const handleCategories = categories.map((doc, index) => <option key={index} value={doc}>{doc}</option>);

    useEffect(() => {

        hideNavbar(true)
    }, [hideNavbar])

    const onSubmit = async (data) => {
        try {
            setIsLoading(true)
            const companyForm = new FormData();
            companyForm.append('companyLogo', data.companyLogo[0]);
            companyForm.append('name', data.name);
            companyForm.append('year', data.year);
            companyForm.append('category', data.category);
            companyForm.append('website', data.website);
            companyForm.append('sede', data.sede);
            companyForm.append('spanishDescription', data.spanishDescription);
            companyForm.append('englishDescription', data.englishDescription);
            companyForm.append('stopInvestingYear', data.stopInvestingYear);
            await createCompany(companyForm);
            reset()
            setTimeout(() => {
                setIsLoading(false)
            }, 3000);
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div className='backoffice'>

            <form className='backoffice__create-company-container' onSubmit={handleSubmit(onSubmit)}>
                {errors.companyLogo && <span className='backoffice__error-inputs'>Este campo es obligatorio</span>}
                <div className='div-logo'>
                    <label>Logo de la Empresa</label>
                    <label htmlFor='logo-upload' className={errors.companyLogo ? ' backoffice__error-inputs backoffice__inputs backoffice__label-cv ' : 'backoffice__inputs backoffice__label-cv'}>

                        {inputFileValue === undefined ? 'File' : inputFileValue.substring(22, -1) + '...'}
                        {
                            isNotMobile ?
                                <label className='backoffice__browse-files-company' htmlFor='logo-upload'>Explorar archivos</label>
                                :
                                <label htmlFor='logo-upload' ><i className="fas fa-upload backoffice__company-logo-upload"></i></label>
                        }
                    </label>
                    <input  
                        name="companyLogo"
                        {...register("companyLogo")}
                        id='logo-upload'
                        onChange={(e) => handleInputFileChange(e)}
                        type="file"
                        className={ errors.companyLogo ? ' backoffice__error-inputs  backoffice__inputs backoffice__upload-logo' : ' backoffice__inputs backoffice__upload-logo'}
                    />
                </div>

                {errors.name && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                <label>Nombre de la Empresa</label>
                <input
                    className={errors.name ? 'backoffice__error-inputs' : 'backoffice__inputs' }
                    name="name"
                    {...register('name')}
                    placeholder='Nombre de la Empresa'
                />

                {errors.year && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                <label>Año de inversión</label>
                <input
                    className={errors.year ? 'backoffice__error-inputs' : 'backoffice__inputs' }
                    name="name"
                    {...register('year')}
                    placeholder='Año de inversión'
                />
                <label>Año de desinversión</label>
                <input
                    className={errors.stopInvestingYear ? 'backoffice__error-inputs' : 'backoffice__inputs' }
                    name="stopInvestingYear"
                    {...register("stopInvestingYear")}
                    placeholder='Año de desinversión'
                />
                {errors.sede && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                <label>Sede</label>
                <input
                    className={errors.sede ? 'backoffice__error-inputs' : 'backoffice__inputs' }
                    name="sede"
                    {...register('sede')}
                    placeholder='Sede'
                />

                {errors.category && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                <label>Categoría</label>
                <select
                    className={errors.category ? 'backoffice__error-inputs' : 'backoffice__inputs' }
                    name="category"
                    {...register('category')}
                    placeholder='Categoría'
                >
                    <option value=''>Seleccionar</option>
                    {handleCategories}
                </select>

                {errors.website && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}
                <label>Sitio web de la Empresa</label>
                <input
                    className={errors.website ? 'backoffice__error-inputs' : 'backoffice__inputs' }
                    name="website"
                    {...register('website')}
                    placeholder='Web'
                />
                {errors.spanishDescription && <span className='backoffice__error-inputs'> Este campo es obligatorio</span>}

                <label>Descripción de la Empresa (ESPAÑOL)</label>
                <textarea
                    className={errors.spanishDescription ? 'backoffice__error-inputs' : 'backoffice__inputs' }
                    type='textarea'
                    name="spanishDescription"
                    {...register('spanishDescription')}
                />

                <label>Descripción de la Empresa (INGLÉS)</label>

                <textarea
                    className={errors.englishDescription ? 'backoffice__error-inputs' : 'backoffice__inputs' }
                    type='textarea'
                    name="englishDescription"
                    {...register("englishDescription")}
                />
                {
                    isLoading ?
                        <p>Compañía añadida a la BBDD correctamente.</p>
                        :
                        <button type='submit' className='backoffice__button'>Crear Participada</button>
                }

            </form>
        </div>
    )
}
