import React from 'react'
import { Link } from 'react-router-dom'

export const Contact = ({ t, showNavbar }) => {


    return (
        <div className='contact'>
            <img className='contact__img' src='/assets/oficina.webp' alt='oficina' />
            <div className='contact__info'>
                <h1 className='contact__heading' id='contacto'>{t('contact')}</h1>
                <span ><a className='contact__logo' style={{ color: 'white', display: 'inline-block', paddingTop: '2em' }} href="/#top">CARTERA DE INVERSIONES </a> <span className='contact__bold'>C.M. </span></span>
                <div className='contact__item'>
                    <img className='contact__icon' src='/assets/contacto_telf.webp' alt='telf' />
                    <span><a className='contact__span' href='tel:+934876666'>T. 934 876 666</a></span>
                </div>
                <div className='contact__item'>
                    <img className='contact__icon' src='/assets/contacto_mail.webp' alt='mail' style={{ width: '1.3em', height: '1em' }} />
                    <span ><a className='contact__span' href='mailto:carteracm@carteracm.com'>carteracm@carteracm.com</a> </span>
                </div>

                <div className='contact__item'>
                    <img className='contact__icon' src='/assets/contacto_gps.webp' alt='gps' />
                    <span className='contact__span'>Paseo de la Bonanova 64, 3º planta</span>
                </div>
                <div className='contact__item'> 08017 Barcelona</div>

            </div>
            <div className='contact__footer'>
                <div className='contact__cookies'>
                    <Link onClick={() => showNavbar(false)} className='contact__link' to='/legal' >{t('legalWarning')}</Link>
                    <span className="contact__separator">|</span>
                    {/* <Link onClick={() => showNavbar(false)}  className='link-cookies' to='/cookies-policy' >{t('cookiesPolicy')}</Link>  */}
                    <Link onClick={() => showNavbar(false)} className='contact__link' to='privacy-policy' >{t('privacyPolicy')}</Link>
                </div>
            </div>
        </div>
    )
}
